<template>
  <div class="about">
    <h1> This is about </h1>
  </div>
</template>
<script>
  export default{
    name: 'AboutPage,'
  };
</script>
